html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1 0 auto; /* This will make the content area grow and push the footer down */
}

/* The footer itself doesn't need special styling here as it will be naturally at the bottom */
