@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  .animation-delay-1000 {
    animation-delay: 1s;
  }
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-3000 {
    animation-delay: 3s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

@keyframes section-animation {
  0% {
    width: 0;
  }
  15% {
    width: 100%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 100%;
    opacity: 0;
  }
  to {
    width: 0;
    opacity: 0;
  }
}

.animated-background {

  background-image: url("./assests/animations/textBG.png");
  background-size: contain;
  background-repeat: no-repeat;
  animation: section-animation 5s infinite;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari, and Opera by default */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox by default */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Show the scrollbar when the user hovers over the scrollable element */
  .no-scrollbar:hover::-webkit-scrollbar {
    display: block;
  }

  .no-scrollbar:hover {
    -ms-overflow-style: auto; /* IE and Edge */
    scrollbar-width: auto; /* Firefox */
  }


  h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-3xl;
    @apply font-bold;
  }
  h3 {
    @apply text-2xl;
    @apply font-bold;
  }
  h4 {
    @apply text-xl;
    @apply font-semibold;
  }
  h5 {
    @apply text-lg; /*or text-lg */
    @apply font-semibold;
  }
  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
}

.meteor::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  background: linear-gradient(90deg, #64748b, transparent);
}

/* Add these styles to your existing CSS file */
.perspective {
  perspective: 1000px;
}

.transform-style-preserve-3d {
  transform-style: preserve-3d;
}

.backface-hidden {
  backface-visibility: hidden;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

/* Additional animations for interactive elements */
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-shine {
  animation: shine 1.5s ease-in-out infinite;
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out forwards;
}

.animate-scaleIn {
  animation: scaleIn 0.3s ease-out forwards;
}

/* Blog content styling */
.blog-description {
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.8;
  font-size: 1.05rem;
}

.blog-description h1 {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #f3f4f6;
}

.blog-description h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: #e5e7eb;
}

.blog-description h3 {
  font-size: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: #d1d5db;
}

.blog-description p {
  margin-bottom: 1.25rem;
}

.blog-description a {
  color: #60a5fa;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.blog-description blockquote {
  border-left: 4px solid #6366f1;
  padding-left: 1rem;
  font-style: italic;
  color: #9ca3af;
  margin: 1.5rem 0;
}

.blog-description ul,
.blog-description ol {
  padding-left: 1.5rem;
  margin-bottom: 1.25rem;
}

.blog-description ul {
  list-style-type: disc;
}

.blog-description ol {
  list-style-type: decimal;
}

.blog-description li {
  margin-bottom: 0.5rem;
}

.blog-description pre {
  background-color: #1e1e1e;
  border-radius: 0.375rem;
  padding: 1rem;
  overflow-x: auto;
  margin: 1.5rem 0;
}

.blog-description code {
  font-family: 'Fira Code', 'Courier New', monospace;
  font-size: 0.9em;
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  background-color: rgba(99, 102, 241, 0.1);
}

.blog-description img {
  border-radius: 0.5rem;
  margin: 1.5rem 0;
  max-width: 100%;
}

.blog-description table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
}

.blog-description th,
.blog-description td {
  border: 1px solid #4b5563;
  padding: 0.75rem;
  text-align: left;
}

.blog-description th {
  background-color: #374151;
  font-weight: 600;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-in;
}

/* Share button animation */
.share-icon.text-blue-500 {
  animation: pulse 1s;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* Copy feedback animation */
.copy-feedback {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1f2937;
  color: white;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.copy-feedback.show {
  opacity: 1;
}

/* Custom modal for share dialog */
.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.share-modal-content {
  background-color: #1f2937;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.share-input-container {
  display: flex;
  margin-bottom: 15px;
}

.share-url-input {
  flex: 1;
  padding: 8px 12px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #374151;
  background: #111827;
  color: white;
}

.share-copy-btn {
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 0 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.share-copy-btn:hover {
  background: #2563eb;
}